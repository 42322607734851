import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import { Row, Col, Alert } from 'react-bootstrap';
import { MARKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { SectionHeader } from '../modules/common/components/section-header';
import { Seo } from '../modules/common/components/seo';
import { ShipIcon } from '../modules/cs/common/components/ship-icon';
import { IShipNode } from '../modules/common/model/graphql-types';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';

import './employee.scss';
import { RatingBox } from '../modules/common/components/rating-box';
import { CSShipType } from '../modules/cs/common/components/ship-type';

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p className="align-center">{children}</p>;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>
  },
  renderNode: {
    ['BLOCKS.PARAGRAPH']: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IShipNodes {
  nodes: IShipNode[];
}

interface IShipEntry {
  currentUnit: IShipNodes;
}

interface IProps {
  data: IShipEntry;
}

const ShipDetails: React.FC<IProps> = ({ data }) => {
  const ship = data.currentUnit.nodes[0];

  return (
    <DashboardLayout className={'generic-page character-page ship'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/ships">Ships</Link>
        </li>
        <li className="divider">/</li>
        <li>{ship.shortName}</li>
      </ul>
      <div className="character-header">
        <ShipIcon ship={ship} />
        <div className="character-details">
          <h1>{ship.shortName}</h1>
          <div className="character-roles-and-types">
            <CSShipType type={ship.class} />
          </div>
        </div>
        {ship.pvpScore ? (
          <div className="character-ratings">
            <RatingBox
              game="cs-old"
              rating={ship.pveScore}
              ratingName="PVE"
              customClass="kr-character"
            />
            <RatingBox
              game="cs-old"
              rating={ship.pvpScore}
              ratingName="PVP"
              customClass="kr-character"
            />
          </div>
        ) : (
          <p className="no-ratings">Ratings are pending review.</p>
        )}
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      <SectionHeader title="Profile" />
      <Row xs={1} xl={2} xxl={3} className="info-list">
        <Col>
          <h5>Personal information</h5>
          <div className="info-list-row">
            <div className="category">Character ID</div>
            <div className="details">#{ship.unitId}</div>
          </div>
          <div className="info-list-row">
            <div className="category">Full Name</div>
            <div className="details">{ship.fullName}</div>
          </div>
          <div className="info-list-row">
            <div className="category">Rarity</div>
            <div className="details">
              <span className={`color-rarity ${ship.rarity}`}>
                {ship.rarity}
              </span>
            </div>
          </div>
        </Col>
        <Col>
          <h5>Availability</h5>
          <div className="info-list-row">
            <div className="category">Regions</div>
            <div className="details">
              {ship.regions.indexOf('KR') > -1 && <span>KR</span>}
              {ship.regions.indexOf('Global') > -1 && <span>, Global</span>}
            </div>
          </div>
        </Col>
      </Row>
      <SectionHeader title="Skills" />
      <Row xs={1} xxl={2} className="skills">
        {ship.skills.map((skill, index) => {
          return (
            <Col key={index}>
              <div className="skill-box">
                <div className="skill-header">
                  <StaticImage
                    src="../images/counterside/icons/icon_placeholder.png"
                    alt="Placeholder"
                  />
                  <div>
                    <h5 className="skill-name">{skill.fullName}</h5>
                    <span className="skill-type">{skill.type}</span>
                    {skill.cooldownSecs && (
                      <span className="pill cooldown">
                        <FontAwesomeIcon icon={faHistory} width="18" />{' '}
                        {skill.cooldownSecs} seconds
                      </span>
                    )}
                    {skill.isFullMap && (
                      <span className="pill">Full map range</span>
                    )}
                    {!skill.isFullMap && skill.range && (
                      <span className="pill">{skill.range} range</span>
                    )}
                  </div>
                </div>
                <div className="skill-content">
                  <div className="skill-description">
                    <p className="skill-description">
                      {skill.description.description}
                    </p>
                    <hr />
                    <p>
                      <span className="skill-upgrade">Max upgrade bonus:</span>{' '}
                      <span>{skill.buildDescription.buildDescription}</span>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
      <div className="fuse-ad-placeholder" data-fuse="22844297001"></div>
      <SectionHeader title="Analysis" />
      {ship.areRatingsNotFinal ? (
        <Alert variant="primary">
          <Alert.Heading>
            This ship was recently released in KR server
          </Alert.Heading>
          <p>
            The ship was recently released in KR and we need time to properly
            test its abilities before reviewing and rating it.
          </p>
        </Alert>
      ) : (
        <div className="analysis">
          <div className="review raw">
            {ship.review ? (
              <div>{renderRichText(ship.review, options)}</div>
            ) : (
              <Alert variant="primary">
                <Alert.Heading>
                  No review is available for this ship yet.
                </Alert.Heading>
                <p> We will add it as soon as it's possible!</p>
              </Alert>
            )}
          </div>
          <SectionHeader title="Pros &amp; Cons" />
          {ship.pros && ship.cons ? (
            <Row className="pros-cons">
              <Col sm="12" md="6">
                <div className="box pros">
                  <h4>{ship.fullName} Pros</h4>
                  <div className="raw list">
                    {renderRichText(ship.pros, options)}
                  </div>
                </div>
              </Col>
              <Col sm="12" md="6">
                <div className="box cons">
                  <h4>{ship.fullName} Cons</h4>
                  <div className="raw list">
                    {renderRichText(ship.cons, options)}
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <Alert variant="primary">
              <Alert.Heading>
                Pros &amp; Cons for this ship aren't available yet.
              </Alert.Heading>
              <p>We will add them as soon as it's possible!</p>
            </Alert>
          )}
        </div>
      )}
    </DashboardLayout>
  );
};

export default ShipDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const ship = data.currentUnit.nodes[0];

  return (
    <Seo
      title={ship.fullName + ' | Counter Side | Prydwen Institute'}
      image={ship.smallAvatar}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulShip(filter: { id: { eq: $contentfulId } }) {
      nodes {
        ...ShipWithSkillsFieldsFragment
      }
    }
  }
`;
